<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service" class="is-active"><a>Zlecenia serwisowe</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <div class="ym-toptitle-box">
      <div  id="toptitle" class="level ym-toptitle">
        <h2 class="title level-left">Zlecenia serwisowe</h2>
        <div class='buttons level-right'>
          <button v-if="(calendar && calendar.content !== '' && calendar.is_active)" class="button is-medium is-secondary" type="button" @click="isModalActive = !isModalActive">{{ dictionary['calendar'] }}</button>

          <a @click.prevent="addNewOrder" class="button is-medium is-dark"><icon name="plus"></icon><span>Dodaj nowe zlecenie</span></a>
        </div>
      </div>
    </div>    
    <div class="ym-columns is-padding-mobile">
      <div class="column is-12-mobile is-6-desktop">
        <div class="b-field-flex" style="gap: 8px;align-items: center;">
          <div class="ym-searchbox" style="margin-bottom: 0; width: 100%;">
            <b-input ref="searchInput" v-model="searchQuery" size="is-medium" placeholder="SZUKAJ..."></b-input>
            <div class="field level level-item level-right ym-searchbox-right" v-if="this.searchQuery">
              również nieaktywni
              <b-checkbox v-model="searchDeleted"
                size="is-medium"
                true-value="1"
                false-value="0"
                @change.native="toggleSearchDeleted"
                >
              </b-checkbox>                  
            </div>
          </div>    
          <TriStateCheckbox v-model="filters.mine" inputId="isMine" /><label for="isMine" style="cursor: pointer">Moje</label>
        </div>
      </div>
      <div class="column is-3">
        <div class="b-field b-field-flex">
          <b-datepicker
              :mobile-native="false"
              :first-day-of-week="1"
              @input="searchOrdersFromRange"
              placeholder="Przedział dat naprawy"
              v-model="dates"
              icon="calendar-today"
              position="is-bottom-left"
              range>
          </b-datepicker>
          <div v-if="dates.length > 0" @click="allOrders" class="button is-primary is-medium has-icon"><icon name="x"></icon></div>         
        </div>
      </div>
      <div class="column is-3">
          <b-field>
            <b-select @input="searchOrdersFromStatus" v-model="status_id" placeholder="Status zlecenia" expanded>
              <option :value="null">wszystkie</option>
              <option v-for="status in this.$store.state.auth.status" :key="status.id" :value="status.id" :style="`color: ${status.colour}`">{{ status.name }}</option>
            </b-select>
        </b-field>
      </div>      
      <div class="column is-12-mobile is-12-desktop table-service">
       
        <b-table
          :data="orders"

          :row-class="(row, index) => isOldOrder(row)"

          :mobile-cards="true"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort">

          <b-table-column field="number" label="Zlecenie" sortable v-slot="props">
            <router-link tag="a" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}" style="min-height: auto;flex-wrap: wrap">{{ props.row.number }}
              <small v-if="props.row.priority_id > 0" :style="`display:block;color: ${props.row.priority.colour}`">{{ props.row.priority.name }}</small></router-link>            
          </b-table-column>
          <!-- <b-table-column field="device_serial" label="Nr seryjny"  v-slot="props">
            <router-link tag="a" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.device_serial }}</router-link>
          </b-table-column> -->
          <b-table-column field="device_type" :label="dictionary['table_order_repared_at_field_label']" v-slot="props">
            <router-link tag="a" class="text-ellipsis" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.repared_at }}</router-link>
          </b-table-column>
          <b-table-column field="device_type" :label="dictionary['table_order_device_type_field_label']" v-slot="props">
            <router-link tag="a" class="text-ellipsis" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">
              {{ props.row.device_type }}
              
            </router-link>
          </b-table-column>
          <b-table-column field="device_name" :label="dictionary['table_order_device_name_field_label']" v-slot="props">
            <router-link tag="a" class="text-ellipsis" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.device_name }}
              
            </router-link>
          </b-table-column>
          <b-table-column field="customer_name" :label="dictionary['table_order_customer_name_field_label']"  v-slot="props">
            <router-link class="text-ellipsis" tag="a" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.customer_name }}</router-link>
          </b-table-column>
          <b-table-column field="status" :label="dictionary['table_order_status_id_field_label']" v-slot="props">
            <router-link tag="a" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}"><small :style="statusColor(props.row.status_id)">{{ statusName(props.row.status_id) }}</small></router-link>
          </b-table-column>
          <b-table-column width="100" field="action_buttons"  label="" v-slot="props">
            <div class="action_buttons">
              <router-link tag="a" class="button is-medium is-black is-hidden-tablet" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}" style="padding-left: 8px; height: 40px !important; min-height: 40px !important;"><icon name="search"></icon><span>Szczegóły</span></router-link>
              <span v-if="props.row.correspondence_token" class="button is-small40 w40" @click="triggerCorrespondenceLink(props.row.correspondence_token)"><span :data-writer="props.row.correspondence_last ? props.row.correspondence_last.writer : 's'"  class="writer"></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
              </span>
              <span v-else class="button is-small40 w40" @click="triggerCorrespondenceModal(props.row)"><icon name="inbox"></icon></span>
              <span class="button is-small40 w40" @click="triggerSendModal(props.row)"><icon name="mail"></icon></span>
            </div>
          </b-table-column>
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div class="content" style="justify-content: center;font-weight: 600;font-size: 16px; color: #000;" v-if="!loading">
                <p><i class="mdi mdi-magnify mdi-24px" style="position: relative; top: 4px; margin-right: 16px;"></i> Brak wyników wyszukiwania.</p>
              </div>
            </section>
          </template>            
        </b-table>
      </div>
    </div>
    <b-modal v-model="isModalActive" v-if="(calendar && calendar.content !== '' && calendar.is_active)" :width="720" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content content-iframe" v-html="calendar.content"></div>
        </div>
      </div>
    </b-modal>
    <custom-modal v-model="showCorrespondenceModal" @closed="emptyModalTrigger">
      <template v-slot:title>Korespondencja z {{ modalOrder?.number }}</template>
      <div style="margin-top: 16px;margin-bottom: 16px;padding: 0 16px;">
        <ValidationObserver v-slot="{ handleSubmit, errors }" ref="correspondenceObserver">
          <form @submit.prevent="handleSubmit(onCorrespondenceSendSubmit)">
            <div class="field" :class="errors['subject'] && errors['subject'].length > 0 ? 'v-field v-error' : ''">
              <div class="ym-label">
                <label for="f_company_short">Temat</label>
              </div>                  
              <div class="control is-medium">
                <ValidationProvider rules="required" name="subject">
                  <input name="subject" v-model="correspondence.subject" class="input is-medium" />
                </ValidationProvider>
                <span v-if="errors['subject'] && errors['subject'].length > 0"
                  class="help is-danger">{{ errors['subject'][0] }}</span>
              </div>
            </div>
            <div class="field" :class="errors['message'] && errors['message'].length > 0 ? 'v-field v-error' : ''">
              <div class="ym-label">
                <label for="f_company_short">Wiadomość</label>
              </div>                  
              <div class="control is-medium">
                <ValidationProvider rules="required" name="message">
                  <textarea name="message" v-model="correspondence.message" class="input is-medium" />
                </ValidationProvider>
                <span v-if="errors['message'] && errors['message'].length > 0"
                  class="help is-danger">{{ errors['message'][0] }}</span>
              </div>
            </div>
            <button class="button is-black is-medium">Wyślij</button>
          </form>
        </ValidationObserver>
      </div>
    </custom-modal>      
    <custom-modal v-model="showSendModal" @closed="emptyModalTrigger">
      <template v-slot:title>Powiadomienie</template>
      <div style="margin-top: 16px;margin-bottom: 16px;padding: 0 16px;">
        <ValidationObserver v-slot="{ handleSubmit, errors }" ref="observer">
          <form @submit.prevent="handleSubmit(onEmailSendSubmit)">
            <div class="field" :class="errors['subject'] && errors['subject'].length > 0 ? 'v-field v-error' : ''">
              <div class="ym-label">
                <label for="f_company_short">Temat</label>
              </div>                  
              <div class="control is-medium">
                <ValidationProvider rules="required" name="subject">
                  <input name="subject" v-model="mail.subject" class="input is-medium" />
                </ValidationProvider>
                <span v-if="errors['subject'] && errors['subject'].length > 0"
                  class="help is-danger">{{ errors['subject'][0] }}</span>
              </div>
            </div>
            <div class="field" :class="errors['content'] && errors['content'].length > 0 ? 'v-field v-error' : ''">
              <div class="ym-label">
                <label for="f_company_short">Wiadomość</label>
              </div>                  
              <div class="control is-medium">
                <ValidationProvider rules="required" name="content">
                  <textarea name="content" v-model="mail.content" class="input is-medium" />
                </ValidationProvider>
                <span v-if="errors['content'] && errors['content'].length > 0"
                  class="help is-danger">{{ errors['content'][0] }}</span>
              </div>
            </div>
            <button class="button is-black is-medium">Wyślij</button>
          </form>
        </ValidationObserver>
      </div>
    </custom-modal>
  </section>
</template>

<script>
  // import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'
  import debounce from '@/utils/debounce';
  import YMmodal from '@/components/Modal.vue';
  import CustomModal from '@/components/CustomModal'
  import TriStateCheckbox from '@/components/TriStateCheckbox'

  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';

  export default {
    name: 'Service',
    components: {
      // FixedHeader,
      CustomModal,
      ValidationObserver,
      ValidationProvider,
      TriStateCheckbox,
    },
    data() {
      return {
        orders: [],
        dates: [],
        status_id: null,
        loading: false,
        total: 0,
        sortField: 'number',
        sortOrder: 'desc',
        defaultSortOrder: 'asc',
        page: 1,
        perPage: 50,
        searchQuery: '',
        searchDeleted: 0, 
        selected: null,
        isModalActive: false,
        showSendModal: false,
        showCorrespondenceModal: false,
        modalOrder: null,
        correspondence: {
          subject: '',
          message: '',
        },
        mail: {
          subject: '',
          content: '',
        },
        filters: {
          mine: 0
        }    
      }
    },
    watch: {
      searchQuery() {
        this.debouncedSearchQuery()
      },
      filters: {
        handler() {
          this.debouncedSearchQuery()
        },
        deep: true
      }       
    },    
    mounted() {
      // this.$refs.searchInput.$el.children[0].focus()
    }, 
    created() {
      this.debouncedSearchQuery = debounce(this.getOrders, 500)
      this.getOrders()
      document.addEventListener('keyup', this.tableNav)
      Array.from(document.querySelectorAll('table tbody tr')).forEach(node => { node.classList.add('normal') })  
    },
    destroyed() {
      document.removeEventListener('keyup', this.tableNav);
    },
    computed:{
      ...mapGetters({
        dictionary: 'dictionary',
        calendar: 'calendar',
        technical_id: 'auth/userId',
        clientUrl: 'clientUrl',
      }),      
      trows() {
        return document.querySelector('table').rows
      },
      repared_at_from() {
        return this.dates[0]
      },
      repared_at_to() {
        return this.dates[1]
      }
    },     
    methods: {
      ...mapActions({
        getOrdersCall: 'orders/getOrders',
        removeOrderCall: 'orders/removeOrder',
        sentEmailCall: 'orders/sendEmail',
        sendCorrespondenceCall: 'orders/sendCorrespondence',
      }),
      getOrders() {
        this.loading = true
        const { page, perPage, sortField, sortOrder, searchQuery, searchDeleted, repared_at_from, repared_at_to, status_id } = this
        let technical_id = null
        if (this.filters.mine) technical_id = this.technical_id

        this.getOrdersCall({page, perPage, sortField, sortOrder, searchQuery, searchDeleted,  repared_at_from, repared_at_to, technical_id, status_id})
          .then(resp => {
            this.total = resp.page.items
            if(this.total <= this.perPage) {
              document.querySelector('.top .pagination').style.display = "none"
            }   
            this.orders = resp.data
          })
          .catch((error) => {
            this.orders = []
            this.total = 0
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
          .finally(() => this.loading = false)
      },
      orderRemove(id) {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: 'Czy na pewno chcesz usunąć zlecenie?',
            content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
            cancel: 'Anuluj',
            ymHasIcon: true,
            ymIcon: 'trash',
            submit: `<span class="mal50i">Usuń</span>`,
            ymConfirm: true,
              ymOnConfirm: () => {
                this.removeOrderCall({id})
                .then(({ data }) => {
                  document.querySelector('.is-cancel').click()
                  this.getOrders()
                  this.$buefy.toast.open({
                      duration: 5000,
                      message: data.alert[1],
                      type: 'is-success',
                      position: 'is-bottom'
                  })  
                })
                .catch(() => {
                  document.querySelector('.is-cancel').click()
                })                 
              }
          }
        }) 
      },
      onPageChange(page) {
        this.page = page
        if(this.searchQuery === '' || this.searchQuery === null) {
          this.getOrders()
        }
      },
      onSort(field, order) {
        this.sortField = field
        this.sortOrder = order
        this.getOrders()
      },
      tableNav($event) {
        var trows = this.trows, trow, nextrow;

        switch($event.keyCode){
          case 13: {
            return active()
          }
          case 38: {
            return movehighlight(-1, $event)
          }
          case 40: {
            return movehighlight(1, $event);
          }
          default: {
            return true;
          }
        }    
        
        function active() {
          document.querySelector('table tbody tr.is-selected a').click()
        }
        
        function movehighlight(way, e) {
          e.preventDefault && e.preventDefault();
          e.returnValue = false;
          var idx = highlightRow(true); //gets current index or null if none highlighted
          
          if(typeof idx === 'number'){//there was a highlighted row
            idx += way; //increment\decrement the index value
            if(idx && (nextrow = trows[idx])){ return highlightRow.apply(nextrow); } //index is > 0 and a row exists at that index
            else if(idx){ return highlightRow.apply(trows[1]); } //index is out of range high, go to first row
            return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
          }
          return highlightRow.apply(trows[way > 0? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow   
        }

        function highlightRow(gethighlight) { //now dual use - either set or get the highlighted row
          gethighlight = gethighlight === true;
          var t = trows.length;
          while (--t > -1) {
            trow = trows[t];
            if(gethighlight && trow.className === 'is-selected'){return t;}
            else if (!gethighlight && trow !== this) { trow.className = 'normal'; }
          }//end while

          return gethighlight? null : this.className = this.className === 'is-selected'? 'normal' : 'is-selected';
        }            
      },      
      toggleSearchDeleted() {
        this.debouncedSearchQuery()          
      },
      addNewOrder() {
        Promise.all([
          this.$store.commit('orders/setOrder', {}),
          this.$store.commit('orders/setCustomer', {}),
          this.$store.commit('orders/setTechnician', {}),
          this.$store.commit('orders/setOperator', {}),
          this.$store.commit('orders/setDevice', {})
        ]).then(() => this.$router.push({ name: 'ServiceAdd' }))
      },
      searchOrdersFromRange() {
        this.getOrders()
      },
      searchOrdersFromStatus() {
        this.getOrders()
      },      
      allOrders() {
        this.dates = []
        this.getOrders()
      },
      statusName: function(id) {
        let status = this.$store.state.auth.status.filter(item => item.id === id)
        if(status.length > 0) {
          return status[0].name
        }
      },
      statusColor: function(id) {
        let status = this.$store.state.auth.status.filter(item => item.id === id)
        if(status.length > 0) {
          return `color: ${status[0].colour}`
        }
      },
      isOldOrder(order) {
        function isDateGreater (d1, d2, days) {
          d1 = new Date(d1);
          return +new Date(d2) > d1.setDate(d1.getDate() + (days||0))
        }
        if(order.status_id === 40 && isDateGreater(order.repared_at, new Date(), 4)) {
          // return 'is-old'
          return
        }
        return
      },
      emptyModalTrigger() {
        this.correspondence = {
          subject: '',
          message: '',
        }
        this.mail = {
          subject: '',
          content: '',
        }
        this.$nextTick(() => {
          this.$refs.correspondenceObserver.reset()
          this.$refs.observer.reset()
        });
        this.modalOrder = null
      },  
      triggerCorrespondenceModal(item) {
        this.showCorrespondenceModal = true
        this.modalOrder = item
      },          
      triggerCorrespondenceLink(token) {
        const correspondence_url = `${this.clientUrl.content}/correspondence/${token}`
        window.open(correspondence_url, '_blank');
      },   
      triggerSendModal(order) {
        this.showSendModal = true
        this.modalOrder = order
      },
      onEmailSendSubmit() {
        const { subject, content } = this.mail
        const order_ids = []
        if(this.modalOrder?.id) {
          order_ids.push(this.modalOrder.id)
        } 
        this.sentEmailCall({subject, content, order_ids}).then(() => {
          this.showSendModal = false
        })
      },
      onCorrespondenceSendSubmit() {
        const { subject, message } = this.correspondence
        const order_id = this.modalOrder.id

        this.sendCorrespondenceCall({subject, message, order_id}).then((data) => {
          const idx = this.orders.findIndex(item => item.id === data.data.order.id)
          // const tableIdx = this.$refs.tableRef.actualRenderTableData.findIndex(item => item.id === data.data.order.id)
          this.orders[idx].correspondence_token = data.data.order.correspondence_token
          // this.$refs.tableRef.actualRenderTableData[tableIdx].correspondence_token = data.data.order.correspondence_token
          this.showCorrespondenceModal = false
        })
      },                         
    }
  }
</script>

<style lang="scss" scoped>
.action_buttons {
  justify-content: space-around;
}
.button.w40 {
  width: 40px;
}
.writer {
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
  background-color: #000;
  margin: 0;
  border: 1px solid black;
  border-radius: 50%;
  z-index: 122;
  &[data-writer]:before {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }
  &[data-writer=s]:before {
    content: 'b';
  }
  &[data-writer=r]:before {
    content: 'k';
  }
}
</style>

<style>
.content-iframe{
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.content-iframe iframe,
.content-iframe object,
.content-iframe embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 851px) {
  .content-iframe {
    min-width: 80vw;
    min-height: calc(100vh - 210px);
  }
}
</style>
